import React from 'react';
import { Link } from "gatsby"
import FacebookBadge from '../../components/FacebookBadge';
import Layout from '../../components/layout';
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import Grid from '@material-ui/core/Grid';
import { faClock, faUser} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './articles.css';

//Import At least four Images for the Article
import articleImage_1 from '../../images/services/Savente-Consulting-Limited-Services-01.jpg';
import articleImage_2 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-03.jpg';
import articleImage_3 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-01.jpg';
import articleImage_4 from '../../images/social-media-images/SL-120219-25650-11 [Converted]-04.jpg';

const articleDetails = {
         title: "Clinical Research Organizations in Nigeria",
         date: "28th March, 2021",
         datePublished: "2021-03-28T08:00:00+08:00", // Y-M-d
         dateModified: "2021-03-28T08:00:00+08:00", //
         author: "Savante Consulting Limited",
         supurb: "As one of the leading clinical research organizations in Nigeria, Savante Consulting Limited provides the resources and expertise you need for your clinical research, we have the right package of clinical research services to deliver to the highest standards throughout your drug development plan."
    }
;

const images = [
    {
      image: articleImage_1,
      alt: 'Face Painting',
      key:1,
    },
    {
        image: articleImage_2,
        alt: 'Face Painting',
        key:2,
    },
    {
        image: articleImage_3,
        alt: 'Face Painting',
        key:3,
    },
    {
        image: articleImage_4,
        alt: 'Face Painting',
        key:4,
    },
  ];

const listItems = images.map((image) => <div key={image.key}  className="col s12 l3 hide-on-small-only">
        <img src={image.image} className="responsive-img" alt={image.alt} />
        </div>);

export default function Partnerships(){

    return(
        <Layout>
            <article>
            <SEO 
                title={articleDetails.title} 
                description={articleDetails.supurb}
                image={articleImage_2}

            />
            <Helmet>
            <script type="application/ld+json">
                {`                    
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "`+articleDetails.title+`",
                        "image": [
                          "`+articleImage_1+`",
                          "`+articleImage_2+`",
                          "`+articleImage_3+`",
                          "`+articleImage_4+`",
                         ],
                        "datePublished": "`+articleDetails.datePublished+`",
                        "dateModified": "`+articleDetails.dateModified+`"
                      }
                  
                  
                `}
            </script>
            </Helmet>
            <br />
            <br />
            <div className="container article">


                <h1 className="title secondary-color-dark-text mb-5">{articleDetails.title}</h1>     
                <Grid container>
                            <Grid  item xs={12}>
                                <time className="pr-10">
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faClock} />
                                    <span>{articleDetails.date}</span>
                                </time>
                                <time>
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faUser} />
                                    <span>{articleDetails.author}</span>
                                </time>
                            </Grid>
                </Grid>
                <div className="row">
                    <div className="col s12 l8 article-body">

                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light  getqouteButton hide-on-large-only  mb-10 mt-10" style={{width: '100%'}}>Contact Us</Link>   

                        <div class="justify">
                            <h5><strong><span data-preserver-spaces="true">Clinical Research</span></strong></h5>
                            <p><span data-preserver-spaces="true">Savante Consulting Limited provides Clinical research services for multi-phase trials with a focus on early development and biometrics, a leading life sciences CRO.</span></p>
                            <p><span data-preserver-spaces="true">As one of the leading&nbsp;</span><span data-preserver-spaces="true">clinical research organizations in Nigeria</span><span data-preserver-spaces="true">, to help you gain the resources and expertise you need for your&nbsp;</span><span data-preserver-spaces="true">clinical research</span><span data-preserver-spaces="true">, we have the right package of clinical research services to deliver to the highest standards throughout your drug development plan.</span></p>
                            <p><span data-preserver-spaces="true">As a leading Nigerian early phase contract research organization with over 10 years of experience in&nbsp;</span><span data-preserver-spaces="true">clinical research</span><span data-preserver-spaces="true">, we are uniquely placed to help you design, develop and implement clinical trial solutions for your specific needs. As one of the best&nbsp;</span><span data-preserver-spaces="true">regulatory consultants in Nigeria</span><span data-preserver-spaces="true">, we help optimize your clinical timelines and support regulatory submissions to bring your product to market quickly and safely. We support you with fast, smooth testing and development processes, enabling you to make safe decisions about your product that is based on the most reliable data.</span></p>
                            <h5><strong><span data-preserver-spaces="true">Preclinical development and clinical trials</span></strong></h5>
                            <p><span data-preserver-spaces="true">By the requirements of Nigerian regulatory bodies, our regulatory affairs consulting company is very attentive to the choice of laboratories. Before they get to the list of approved suppliers, laboratories pass a selection process that includes an assessment of the documentation and quality audit. In the future, we conduct a regular audit of the laboratory, as well as for-cause audits.</span></p>
                            <p><span data-preserver-spaces="true">Savante Consulting Limited provides a comprehensive range of services in international and local clinical trials, observational studies, data management, biostatistics, medical writing and training programs in&nbsp;</span><span data-preserver-spaces="true">Africa</span><span data-preserver-spaces="true">.</span></p>
                            <p><span data-preserver-spaces="true">Our CRO is committed to meeting the needs and expectations of our clients by providing professional and superior quality service, with integrity, reliability and strong client focus.</span></p>
                            <h5><strong><span data-preserver-spaces="true">Pharmacovigilance</span></strong></h5>
                            <p><span data-preserver-spaces="true">Savante Consulting Limited can serve as a local Pharmacovigilance Agent for products in Nigeria and Kenya. We will generate necessary documentation, including ICSR and PSUR to support continuing registration of the product in the local market. Savante will provide a call desk and data management support system that meets the requirements of Pharmacovigilance guidelines of the regulatory authorities</span></p>
                            <p><span data-preserver-spaces="true">Pharmacovigilance during the clinical development of the pharmaceutical product is a collection of safety information, analysis, and reporting to the competent authorities and all relevant parties.</span></p>
                            <p><span data-preserver-spaces="true">Information gathering and distribution is performed centrally for all trials conducted with the product. This means that in case of new safety information available in one study, the new data will be received at all sites and regulatory authorities in all studies with this drug.</span></p>
                            <h5><strong><span data-preserver-spaces="true">Contract manufacturing organization</span></strong></h5>
                            <p><span data-preserver-spaces="true">Savante Consulting Limited is a leading pharmaceutical contract development and manufacturing organization (CDMO) located in Lagos Nigeria. The company offers pharmaceutical development services. It provides seamless tech transfer from their development facilities and commercial supply of a wide range of formulations.</span></p>
                            <h5><strong><span data-preserver-spaces="true">Partnership - Organization legal structure</span></strong></h5>
                            <p><span data-preserver-spaces="true">Savante Consulting Limited will strive to reduce and manage business risks by providing our clients and their financiers with business decisions and timely executions of projects.</span></p>
                            <p><span data-preserver-spaces="true">Regulatory support is a complex process that ensures that the parameters of the clinical development process meet its main goal of obtaining a marketing authorization.</span></p>
                            <p><span data-preserver-spaces="true">To avoid various risks it is necessary to evaluate the entire clinical development program. We collaborate with professionals from various scientific fields to comprehensively predict possible outcomes of clinical research and achieve expected results. If necessary we make requests to the regulatory authorities to obtain scientific advice.</span></p>
                        </div>
                        
                    </div>
                    <div className="col s12 l4 p-0 center">
                        <Link to="/contact-us" className="btn btn-small waves-effect waves-light hide-on-small-only contact-us-btn" style={{width: '100%'}}>Contact Us</Link>
                        <br />
                        <br />
                        <FacebookBadge
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsavanteconsultingofficial%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
                        />
                        <br />
                        <iframe 
                            title="Google Badge"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5355607532397!2d3.387330214770342!3d6.45361289533113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Limited!5e0!3m2!1sen!2sru!4v1615677592874!5m2!1sen!2sru"
                            allowFullScreen=""
                            width="340"
                            height="300" 
                            frameBorder="0">

                        </iframe>
                    </div>
                </div>
            </div>
            </article>
        </Layout>
    );
}